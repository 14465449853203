import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Login } from "./pages/Login";
import "./App.css";
import { documentListLoader, ShareResults } from "./pages/ShareResults";
import { Layout } from "./layout/Layout";
import { TermsAndConditions } from "./pages/TermsAndConditions";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { RegisterConfirmation } from "./pages/RegisterConfirmation";
import { DocumentDetails, documentLoader } from "./pages/DocumentDetails";
import {  DocumentListWrapper } from "./components/DocumentListWrapper";
import { ApiError } from "./components/ApiError";
import { Support } from "./pages/Support";
import { Suspense } from "react";
import { Loader } from "./components/Loader";
import { DEFAULT_LOCALE } from './i18n';

const resultsMap: Record<string, string> = {
 pl: "Wszystkie badania",
 en: "All results",
 pob: "Todos os resultados",
}

const documentMap: Record<string, string> = {
  pl: "Wyniki badań",
  en: "Result details",
  pob: "Detalhes do resultado",
};

export const routes = (language: string) => {
  return [
    {
      path: "/",
      element: <Layout />,
      children: [
        { index: true, element: <Login /> },
        {
          path: "/patient",
          element: <Login isPatient />,
        },
        {
          path: "/results",
          breadcrumb: resultsMap[language] || "All results",
          element: <ShareResults />,
          loader: documentListLoader,
          children: [
            { index: true, element: <DocumentListWrapper />, errorElement: <ApiError /> },
            {
              path: ":documentId",
              element: <DocumentDetails />,
              loader: documentLoader,
              errorElement: <ApiError />,
              breadcrumb: documentMap[language] || "Result details",
            },
          ],
        },
        {
          path: "/terms",
          element: <TermsAndConditions />,
        },
        {
          path: "/privacy-policy",
          element: <PrivacyPolicy />,
        },
        {
          path: "/register-confirm",
          element: <RegisterConfirmation />,
        },
        {
          path: "/support",
          element: <Support />,
        },
      ],
    },
  ];
};

const router = createBrowserRouter(routes(DEFAULT_LOCALE));

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  );
}

export default App;
