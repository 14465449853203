import React from "react";
import { Box, Stack, Typography, Link } from "@mui/material";
import googlePlayBadgePL from "../assets/images/pl/google-play-badge.png";
import googlePlayBadgeEN from "../assets/images/en/google-play-badge.png";
import googlePlayBadgePOB from "../assets/images/pob/google-play-badge.png";
import appStoreBadgePL from "../assets/images/pl/app-store-badge.svg";
import appStoreBadgeEN from "../assets/images/en/app-store-badge.svg";
import appStoreBadgePOB from "../assets/images/pob/app-store-badge.svg";
import appScreenPL from "../assets/images/pl/app-screen.png";
import appScreenEN from "../assets/images/en/app-screen.png";
import appScreenPOB from "../assets/images/pob/app-screen.png";
import { useTranslation } from "react-i18next";

const googleMap: Record<string, string> = {
  pl: googlePlayBadgePL,
  en: googlePlayBadgeEN,
  pob: googlePlayBadgePOB,
};

const appleMap: Record<string, string> = {
  pl: appStoreBadgePL,
  en: appStoreBadgeEN,
  pob: appStoreBadgePOB,
};

const logoMap: Record<string, string> = {
  pl: appScreenPL,
  en: appScreenEN,
  pob: appScreenPOB,
};

export const LoginPatient: React.FC = () => {
  const { t, i18n } = useTranslation();
  const googlePlayBadge = googleMap[i18n.language] || googlePlayBadgeEN;
  const appStoreBadge = appleMap[i18n.language] || appStoreBadgeEN;
  const appScreen = logoMap[i18n.language] || appScreenEN;
  return (
    <Box minHeight={278}>
      <Typography variant='h2'>{t("login.patient.header")}</Typography>
      <Box mt={1}>
        <Typography variant='body1' component='ol'>
          <li>{t("login.patient.step1")}</li>
          <li>{t("login.patient.step2")}</li>
        </Typography>
      </Box>
      <Stack direction='row' spacing={3} alignItems='flex-end'>
        <Box sx={{ position: "relative", minWidth: "141px", height: "190px" }}>
          <img
            style={{ position: "absolute", bottom: "-32px" }}
            src={appScreen}
            width={141}
            alt={t("login.patient.altImage")}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            overflowWrap: "anywhere",
          }}
        >
          <Link href='https://play.google.com/store/apps/details?id=pl.teczkapacjenta' target='_blank'>
            <img src={googlePlayBadge} width={155} alt={t("login.patient.altGoogle")} />
          </Link>
          <Link href='https://apps.apple.com/pl/app/teczka-pacjenta/id6464666410' target='_blank'>
            <img src={appStoreBadge} width={155} alt={t("login.patient.altMac")} />
          </Link>
        </Box>
      </Stack>
    </Box>
  );
};
