import { Box } from "@mui/material";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

// generate fetch html from s3 url and save it to const variable to use it in dangerouslySetInnerHTML 
const fetchHtml = async (url: string): Promise<string> => {
  const response = await fetch(url);
  const html = await response.text();
  return html;
}


export const PrivacyPolicy: React.FC = () => {
  const { i18n } = useTranslation();
  const lang = i18n.language
  const [html, setHtml] = React.useState<string>('');

  useEffect(() => { 
    fetchHtml(`${process.env.REACT_APP_AWS_S3_PUBLIC_BUCKET_URL}/${lang}/privacy-policy.html`).then((html) => {
      setHtml(html);
    });
  }, [html, lang]);

  return (
    <Box display='flex' justifyContent='center' alignItems='center' style={{ width: "100%"}}>
        <style>
                {`
                .policyWrapper div {
                  display: flex;
                  width: 100%;
                  flex-direction: column;
                `}
              </style>
      <div className="policyWrapper" dangerouslySetInnerHTML={{ __html: html }} style={{display: "flex", width: "100%"}} />
    </Box>
  );
};
