import { useLocation, useNavigate } from "react-router-dom";
import { AppBar, Box, Container, Grid, Stack, Toolbar, Typography } from "@mui/material";
import { MainNavigation } from "./MainNavigation";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { ResponsiveIconButton } from "../components/ResponsiveIconButton";
import logoPl from "../assets/images/teczka-pacjenta-logo.svg";
import logoEn from "../assets/images/logo-en.svg";
import logoPon from "../assets/images/logo-pob.svg";
import { ShareResultsNav } from "./ShareResultsNav";
import { SearchBar } from "../components/SearchBar";
import { useTranslation } from "react-i18next";

const logoMap: Record<string, string>  = {
  pl: logoPl,
  en: logoEn,
  pob: logoPon,
};

export const MainBar = () => {
  const { t, i18n } = useTranslation();
  const logo = logoMap[i18n.language] || logoEn;
  const navigate = useNavigate();
  const location = useLocation();
  const token = sessionStorage.getItem("token");
  const firstName = sessionStorage.getItem("firstName");
  const lastName = sessionStorage.getItem("lastName");
  const isLogged = token !== null;

  const logout = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("firstName");
    sessionStorage.removeItem("lastName");
    return navigate("/");
  };

  return (
    <div>
    <AppBar position='sticky' color='default' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      {isLogged && (
        <Box
          sx={{
            backgroundColor: "#C2D0FF",
            paddingX: 3,
            paddingY: 1,
            minHeight: "66px",
          }}
        >
          <Container maxWidth={"xl"} disableGutters>
            <Grid container direction='row' alignItems='center'>
              <Grid xxs={12} md={2} item>
                <img src={logo} alt={t("logo.alt")} width='100' />
              </Grid>
              <Grid xxs={12} md={8} item>
                {!location.pathname.endsWith("results") ? null : <SearchBar />}
              </Grid>
              <Grid xxs={12} md={2} item display='flex' justifyContent='flex-end'>
                <ResponsiveIconButton
                  text={t("logoutButton.text")}
                  icon={<FontAwesomeIcon icon={faRightFromBracket} />}
                  onClick={logout}
                  variant='outlined'
                  size='small'
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
      )}
      <Container maxWidth={"xl"} disableGutters>
        <Toolbar disableGutters={!isLogged}>
          {isLogged ? (
            <>
              <Stack flexGrow={1}>
                <Stack flexGrow={1} direction="row">
                  <Box pt={1}>
                    <Typography variant='subtitle1' color='text.secondary'>
                      {t("mainBar.text")}
                    </Typography>
                    <Typography variant='h2' color='text.primary'>
                      {firstName} {lastName}
                    </Typography>            
                  </Box>
                  <Box pt={1} 
                  // sx={{ mx: 5 }}
                  sx={(theme) => ({
                    mx: 2,
                    [theme.breakpoints.up('md')]: {
                      mx: 15,
                    },     
                    [theme.breakpoints.down('sm')]: {
                      mx: 2,
                    },                                    
                    [theme.breakpoints.down('xs')]: {
                      display: 'none',
                    },
                  })}
                  >
                    <Typography variant="body1" color="text.primary"
                      sx={(theme) => ({
                        fontSize: {
                          xs: '0.675rem',
                          sm: '0.775rem',
                          md: '1rem',
                        },
                      })}
                    >
                        <span
                          dangerouslySetInnerHTML={{
                            __html: t('snackbar.welcome', {
                              interpolation: { escapeValue: false },
                            }),
                          }}
                        />
                      </Typography>
                  </Box>
                  </Stack>
                <ShareResultsNav />
              </Stack>
            </>
          ) : (
            <MainNavigation />
          )}
        </Toolbar>
      </Container>
    </AppBar>

    </div>
  );
};
